<script>
import CandidatesEmailTemplateValidator from "../../core/models/candidatesEmailTemplateValidator"
import errorsMixin from '@/mixins/errors.mixin';

export default {
  render() {
    return this.$scopedSlots.default({
      sendEmail: this.sendEmail
    })
  },

  inject: {
    parentSvc: 'svc'
  },

  mixins: [errorsMixin],

  props: {
    activeItems: Array,
    formErrors: Object
  },

  data: () => ({
    templateValidator: new CandidatesEmailTemplateValidator(),
  }),

  methods: {
    async sendEmail(data, callback) {
      if(!data.readonly && !this.templateValidator.validate(this.formErrors, data)) {
        this.$notify({text: 'Invalid fields', type: 'error'})
        return
      }
      try {
        this.$emit('loading:start')
        let ids = this.activeItems.map(item => item.ID)
        let res = await this.parentSvc().sendEmailBulk(ids, data)
        callback()
        this.$notify({text: 'Email send', type: 'success'})
        this.$emit('send', res)
      } catch (error) {
        console.log(error)
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors, 
          apiAttributes: this.templateValidator.apiAttributes,
        });
        this.$notify({text: 'Sending error', type: 'error'})
      }
      finally {
        this.$emit('loading:end')
      }
    }
  },
}
</script>